<template>
  <div class="video__card" :class="classCard" @click="openMediaModal(0)">
    <div class="video__card-media">
      <video
        v-if="previewVideoSrc"
        :controls="false"
        loop
        muted
        autoplay
        playsinline
        :poster="`${url}${image.source}`"
      >
        <source :src="`${url}${previewVideoSrc}`" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <UIPictureTag
        v-else
        :image-original="`${url}${image.source}`"
        :image-seo="imageSeo"
        :image-webp="`${url}${image.webp}`"
      />
    </div>
    <div class="video__card-content" :class="classContent">
      <h6 class="video__card-title">{{ title }}</h6>
      <div class="video____card-desc">{{ description }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ImageItem, ImageSEO } from '~/types/pages';
import { computed, onUnmounted, useMediaModal, useRuntimeConfig } from '#imports';
import './styles.scss';

interface Props {
  previewVideoSrc: string | null;
  fullVideoLink: string;
  image: ImageItem;
  imageSeo: ImageSEO;
  title: string;
  description: string;
  classCard?: string;
  classContent?: string;
  review_media_orientation?: string;
}

const props = defineProps<Props>();

const url = useRuntimeConfig().public.siteUrl;

const dataSource = computed(() => ({
  html:
    props?.review_media_orientation === 'vertical'
      ? `<div class="reelse__frame">
            <iframe
              src="${props?.fullVideoLink}"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen>
            </iframe>
         </div>`
      : `<iframe src="${props?.fullVideoLink}?rel=0"
                    frameborder="0"
                    allow="
                     accelerometer;
                     autoplay;
                     clipboard-write;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture;
                     web-share"
                    allowfullscreen>
                   </iframe>`,

}));

const { destroyMediaModal, openMediaModal } = useMediaModal([dataSource.value]);

onUnmounted(() => destroyMediaModal());
</script>

<style scoped></style>
